import devtools from "@vue/devtools";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

if (process.env.NODE_ENV === "development") {
  devtools.connect(/* host, port */);
}

Vue.config.productionTip = false;

import VueRippler from "vue-rippler";
Vue.use(VueRippler);

import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storag
import "firebase/firestore"; // for cloud firestore
import "firebase/functions"; // for cloud functions

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCmztJw4zyAVEoYzng64jRe8liAItD9Ojw",
  authDomain: "holosno-com-ua.firebaseapp.com",
  projectId: "holosno-com-ua",
  storageBucket: "holosno-com-ua.appspot.com",
  messagingSenderId: "879361114592",
  appId: "1:879361114592:web:5a5ce30a87e08061cbeb85",
  measurementId: "G-3N29QLM0M5",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  firebase,
  render: (h) => h(App),
}).$mount("#app");
