<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  mounted() {},
};
</script>
<style lang="scss">
.arrow-block {
  height: 30vh;
  border-left: 2px solid #000;
  margin-top: 64px;
  width: calc(50vw - 22px);
  margin-left: auto;
  margin-right: 22px;
  box-sizing: border-box;
  border-top: 2px solid #000;
  position: relative;
  &::before {
    display: block;
    right: 0;
    top: -40px;
    position: absolute;
    content: "";
    height: 40px;
    width: 2px;
    background: #000;
  }
  &::after {
    display: block;
    right: 1px;
    transform: translateX(50%);
    top: -46px;
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #000000 transparent;
  }
}
#inapp-overlay {
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  align-items: center;
  display: none;
  flex-direction: column;
}
#inapp-overlay.show {
  display: flex !important;
}
.pulsating-circle {
  position: absolute;
  right: 0%;
  top: 0%;
  transform: translateX(-100%) translateY(-200%);
  width: 50px;
  height: 50px;
}
#useragent {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#useragent span {
  text-transform: uppercase;
  text-align: center;
  font-size: 13px;
}
#useragent p {
  color: #fff;
  background: #000;
  text-transform: uppercase;
  text-align: center;
  padding: 15px;
  font-size: 13px;
}

.pulsating-circle:before {
  content: "";
  position: relative;
  display: block;
  width: 500%;
  height: 500%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 50%;
  background-color: #000;
  animation: pulse-ring 1.6s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
.pulsating-circle:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
</style>
